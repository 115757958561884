import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import {
  OrderListModel,
  ListStateModel,
  DisbursementStateModel,
  RepaymentStateModel,
  DisburseGroupDetailModel,
  DisburseGroupOrderListModel,
} from './model';
import { CHARGE_MODE } from '@/types/constant';
import History from './interface';
import dayjs from '@/utils/dayjs';
import common from '@/utils/v2/common';

export class HistoryService extends BaseService {
  @observable weeklyListState: ListStateModel = {
    list: [],
    page_index: 0,
    page_size: 10,
    has_more: true,
    loading: false,
    total: 0,
    actual_disburse_amount: 0,
  };

  @observable monthlyListState: ListStateModel = {
    list: [],
    page_index: 0,
    page_size: 10,
    has_more: true,
    loading: false,
    total: 0,
    actual_disburse_amount: 0,
  };

  // @observable disbursementState: DisbursementStateModel = {
  //   listSrc: [], // list原始数据
  //   list: [], // list数据，给ListView组件使用的
  //   page_index: 0, // 当前请求的页数
  //   page_size: 10, // 每页请求多少条数据
  //   has_more: true, // 是否还有下一页的数据
  //   loading: false,
  //   total: 0, // 列表总数（包括已加载和未加载）
  //   details: {}, // disbursement 详情
  // };

  // 放款详情页订单List
  @observable loading: boolean = false;
  @observable hasMore: boolean = true;
  @observable dataList: OrderListModel[] = [];

  protected page_index = 0;
  protected page_size = 10;

  // // 放款详情页订单List
  // @observable disbursementOrderList: DisburseGroupOrderListModel =
  //   new DisburseGroupOrderListModel();
  // 详情
  @observable disbursementDetail: DisburseGroupDetailModel = new DisburseGroupDetailModel();

  @observable repaymentState: RepaymentStateModel = {
    plan_status: 1, // 还款状态
    actual_advance_time: 0, // 实际放款成功的时间
    overdue_time: 0, // 逾期的时间
    repay_time: 0, // 应还款时间即FDD时间
    principal_amount: '', // 本金
    actual_disburse_amount: '0', // 实际放款金额，扣除服务费
    interest_amount: '0', // 利息
    outstanding_amount: '0', // 总还款额
    repayment_amount: '0', // 实际还款金额
    total_repaid_amount: '0', // 已还金额
    is_overdue: 0, // 是否逾期
    charge_mode: CHARGE_MODE.upfront,
    service_charge_amount: '0',
    is_adjusted: false,
  };

  constructor() {
    super();
    makeObservable(this);
  }

  /** ***************** List START ************************* */

  @action
  async initListByWeekly(params: { start_time: string; end_time: string }) {
    await this.clearWeeklyList();
    await this.fetchListByWeekly(params);
  }

  @action
  async initListByMonthly(params: { start_time: string; end_time: string }) {
    await this.clearMonthlyList();
    await this.fetchListByMonthly(params);
  }

  @action
  async fetchListByWeekly(params: { start_time: string; end_time: string }) {
    const {
      page_index,
      page_size,
      has_more,
      list,
      actual_disburse_amount: oldAmount,
      loading,
    } = this.weeklyListState;
    console.log('loading :', loading);

    if (!has_more || loading) {
      return;
    }

    this.weeklyListState.loading = true;

    const resp = await this.post(History.API.get_user_disburse_groups, {
      body: {
        pagination: {
          page_size,
          page_index: page_index + 1,
          cursor: list.length > 0 ? list?.[list.length - 1]?.group_id ?? '0' : '0',
        },
        start_time: params?.start_time ? dayjs(params?.start_time).unix() : null,
        end_time: params?.end_time ? dayjs(params?.end_time).unix() : null,
      },
    });
    const { actual_disburse_amount, list: addList, page_info } = resp?.result ?? {};
    const newList = [...list, ...(addList ?? [])];

    this.weeklyListState = {
      ...this.weeklyListState,
      ...(page_info ?? {}),
      list: newList,
      actual_disburse_amount: page_index === 0 ? actual_disburse_amount : oldAmount,
      loading: false,
    };
  }

  @action
  async fetchListByMonthly(params: { start_time: string; end_time: string }) {
    const {
      page_index,
      page_size,
      has_more,
      list,
      actual_disburse_amount: oldAmount,
      loading,
    } = this.monthlyListState;

    if (!has_more || loading) {
      return;
    }

    this.monthlyListState.loading = true;

    const resp = await this.post(History.API.get_user_disburse_groups, {
      body: {
        pagination: {
          page_size,
          page_index: page_index + 1,
          cursor: list.length > 0 ? list?.[list.length - 1]?.group_id ?? '0' : '0',
        },
        start_time: params?.start_time ? dayjs(params?.start_time).unix() : null,
        end_time: params?.end_time ? dayjs(params?.end_time).unix() : null,
      },
    });
    const { actual_disburse_amount, list: addList, page_info } = resp?.result ?? {};
    const newList = [...list, ...(addList ?? [])];

    this.monthlyListState = {
      ...this.monthlyListState,
      ...(page_info ?? {}),
      list: newList,
      actual_disburse_amount: page_index === 0 ? actual_disburse_amount : oldAmount,
      loading: false,
    };
  }

  @action
  async clearList() {
    this.clearWeeklyList();
    this.clearMonthlyList();
  }

  @action
  async clearWeeklyList() {
    this.weeklyListState = {
      list: [],
      page_index: 0,
      page_size: 10,
      has_more: true,
      loading: false,
      total: 0,
      actual_disburse_amount: 0,
    };
  }

  @action
  async clearMonthlyList() {
    this.monthlyListState = {
      list: [],
      page_index: 0,
      page_size: 10,
      has_more: true,
      loading: false,
      total: 0,
      actual_disburse_amount: 0,
    };
  }

  /** ***************** List END ************************* */

  /** ***************** Disbursement START ************************* */

  @action
  async fetchDisbursementDetail(group_id: string) {
    const { result } = await this.post(History.API.get_user_disburse_group, {
      body: {
        group_id,
      },
    });

    // this.disbursementState.details = result || {};
    this.disbursementDetail = result || {};

    return result || {};
  }

  @action
  async fetchDisbursementOrders(payload: string) {
    // 如果已经标记当前状态为hasmore:false
    if (!this.hasMore || this.loading) return;
    this.loading = true;

    const { code, result } = await this.post(History.API.get_group_orders, {
      body: {
        pagination: {
          page_index: this.page_index + 1,
          page_size: this.page_size,
          cursor:
            this.dataList.length > 0
              ? this.dataList?.[this.dataList.length - 1]?.plan_id ?? '0'
              : '0',
        },
        group_id: payload,
      },
    });
    if (code === 0) {
      const { page_info, list = [] } = result;

      this.dataList = list ?? [];
      this.hasMore = page_info.has_more;
      this.page_index = page_info.page_index;
      this.loading = false;
      return result;
    }
    this.loading = false;
    this.hasMore = false;
  }

  @action
  async initDisbursementState() {
    this.loading = false;
    this.hasMore = true;
    this.page_index = 0;
    this.page_size = 10;
    this.dataList = [];
    // this.disbursementState = {
    //   listSrc: [], // list原始数据
    //   list: [], // list数据，给ListView组件使用的
    //   page_index: 0, // 当前请求的页数
    //   page_size: 10, // 每页请求多少条数据
    //   has_more: true, // 是否还有下一页的数据
    //   loading: false,
    //   total: 0, // 列表总数（包括已加载和未加载）
    //   details: {}, // disbursement 详情
    // };
  }

  /** ***************** Disbursement END ************************* */

  /** ***************** Repayment START ************************* */

  @action
  async fetchRepaymentDetailInfo(payload: number | string) {
    const { result } = await this.post(History.API.get_repayment_detail, {
      body: {
        plan_id: typeof payload === 'string' ? payload : payload?.toString(),
      },
    });

    this.repaymentState = {
      ...this.repaymentState,
      ...(result || {}),
    };

    return result || {};
  }

  /** ***************** Repayment END ************************* */
}
