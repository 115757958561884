import { Expose } from 'class-transformer';
import { IAddendumInfo } from './interface';

export class AgreementResponseModel {
  /**
   * 合同英文版
   */
  @Expose()
  contract_url_en!: string;

  /**
   * 合同泰文版
   */
  @Expose()
  contract_url_local!: string;

  /**
   * 合同英文版png
   */
  @Expose()
  contract_picture_url_en!: string;

  /**
   * 合同泰文版png
   */
  @Expose()
  contract_picture_url_local!: string;

  /**
   * Addendum合同列表
   */
  @Expose()
  contract_info_list?: IAddendumInfo[];
}
