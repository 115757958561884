import { formatMessage } from '@/utils/locale';

namespace History {
  export enum API {
    'get_user_disburse_groups' = '/fast-escrow/transaction/query_disburse_group',
    'get_user_disburse_group' = '/fast-escrow/transaction/query_disburse_group_detail',
    'get_group_orders' = '/fast-escrow/transaction/query_group_orders',
    'get_repayment_detail' = '/fast-escrow/transaction/get_repayment_detail',
  }
}

// 还款状态
export enum REPAYMENT_STATUS {
  REPAYING = 1,
  FULLY_REPAID = 2,
}

export enum PLAN_STATUS {
  UNPAID = 1,
  PARTIAL_REPAID = 2,
  REPAID = 3,
}

export enum OVERDUE_STATUS {
  NORMAL = 0,
  OVERDUE = 1,
}

// repayment状态
export const repaymentStatusMap: any = {
  [REPAYMENT_STATUS.REPAYING]: formatMessage({ id: 'history.repayment_status_1' }),
  [REPAYMENT_STATUS.FULLY_REPAID]: formatMessage({ id: 'history.repayment_status_2' }),
};

// repayment状态(埋点上报)
export const repaymentStatusTrackMap: any = {
  [REPAYMENT_STATUS.REPAYING]: 'repaying',
  [REPAYMENT_STATUS.FULLY_REPAID]: 'fully_repaid',
};

export const overdueStatusMap: any = {
  [OVERDUE_STATUS.OVERDUE]: formatMessage({ id: 'disbursement_detail.overdue' }),
};

export const planStatusMap: any = {
  [PLAN_STATUS.UNPAID]: formatMessage({ id: 'disbursement_detail.unpaid' }),
  [PLAN_STATUS.REPAID]: formatMessage({ id: 'disbursement_detail.repaid' }),
  [PLAN_STATUS.PARTIAL_REPAID]: formatMessage({
    id: 'disbursement_detail.partial_repaid',
  }),
};

export const overdueStatusTrackMap: any = {
  [OVERDUE_STATUS.OVERDUE]: 'overdue',
};

export const planStatusTrackMap: any = {
  [PLAN_STATUS.UNPAID]: 'unpaid',
  [PLAN_STATUS.REPAID]: 'repaid',
  [PLAN_STATUS.PARTIAL_REPAID]: 'partial_repaid',
};

export default History;
