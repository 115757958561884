import { Expose } from 'class-transformer';
import { CHARGE_MODE } from '@/types/constant';

export class ListStateModel {
  @Expose()
  list!: ListModel[];

  @Expose()
  page_index!: number;

  @Expose()
  page_size!: number;

  @Expose()
  has_more!: boolean;

  @Expose()
  loading!: boolean;

  @Expose()
  total!: number;

  @Expose()
  actual_disburse_amount!: number;
}

export class ListModel {
  @Expose()
  actual_disburse_time!: number;

  @Expose()
  group_id!: number;

  @Expose()
  actual_disburse_amount!: number;

  @Expose()
  group_status!: number; // 1-repaying,2-fully repaid

  @Expose()
  overdue_status!: number; // group overdue 状态。0-未逾期，1-已逾期

  @Expose()
  charge_mode!: number;

  // @Expose()
  // service_charge_amount!: number;

  // @Expose()
  // due_date!: number;

  // @Expose()
  // disburse_percentage!: number;
}

export class DisbursementStateModel {
  @Expose()
  listSrc!: any[];
  @Expose()
  list!: any[];
  @Expose()
  page_index!: number;
  @Expose()
  page_size!: number;
  @Expose()
  has_more!: boolean;
  @Expose()
  loading!: boolean;
  @Expose()
  total!: number;
  @Expose()
  details!: Partial<DisburseGroupDetailModel>;
}

export class RepaymentStateModel {
  @Expose()
  plan_status!: number; // 还款状态
  @Expose()
  repayment_amount!: string; // 总还款额
  @Expose()
  total_repaid_amount!: string; // 总已还金额
  @Expose()
  actual_advance_time!: number; // 实际放款成功的时间
  @Expose()
  actual_disburse_amount!: string; // 实际放款金额，扣除服务费
  @Expose()
  overdue_time!: number; // 逾期的时间
  @Expose()
  repay_time!: number; // 应还款时间即FDD时间
  @Expose()
  principal_amount!: string; // 本金
  @Expose()
  interest_amount!: string; // 利息
  @Expose()
  outstanding_amount!: string; // 未还本金+未还利息
  @Expose()
  entity_id?: number; // shopee侧的order id
  @Expose()
  order_sn?: string; // order id
  @Expose()
  is_overdue!: number; // 逾期
  @Expose()
  charge_mode!: CHARGE_MODE; // 收费模式
  @Expose()
  service_charge_amount!: string; // 服务费
  @Expose()
  is_adjusted!: boolean; // 是否调整放款比例
}

export class DisburseGroupDetailModel {
  @Expose()
  group_id!: number;
  @Expose()
  total_mortgage_amount!: string; // 总合格订单金额
  @Expose()
  principal_amount!: string; // 未扣除服务费的放款金额
  @Expose()
  actual_disburse_time!: string; // 该group的实际的成功放款时间
  @Expose()
  actual_disburse_amount!: string; // 该group的实际放款金额，扣掉了服务费的
  @Expose()
  principal_outstanding_amount!: string; // 未还本金
  @Expose()
  repayment_status!: number; // group维度的还款状态，0 放款完成，1 还款中，2 全部还款
  @Expose()
  is_overdue!: number; // group维度是否逾期
  @Expose()
  disburse_percentage!: number;
  @Expose()
  interest_rate!: string; // 利息的日利率：repayment amount 的 tooltip 里面的 daily fee rate
  @Expose()
  available_limit!: string; // 用户放款前可用额度
  @Expose()
  total_payable_amount!: string; //
  @Expose()
  total_outstanding_amount!: string; //
  @Expose()
  service_charge_amount!: string;
  @Expose()
  service_charge_rate!: string;
  @Expose()
  interest_amount!: string; // 利息
  @Expose()
  order_income!: string; // 该group的总的order income
  @Expose()
  remaining_amount!: string; // 该group的总的剩余放款金额
  @Expose()
  repayment_amount!: string; // 该group的总的实际已还金额
  @Expose()
  outstanding_amount!: string; // 该group的总的未还金额
  @Expose()
  repay_time!: number; // 该group的还款日
  @Expose()
  receipt_img_url!: string; // 电子发票图片URL，若没有电子发票则为空字符
  @Expose()
  charge_mode!: CHARGE_MODE; // 收费模式（1利息，2 服务费）
  @Expose()
  is_adjusted!: boolean; // 是否调整放款比例
}

export class DisburseGroupOrderListModel {
  @Expose()
  page_info: PageInfo = {
    total: 0,
    page_index: 0,
    has_more: true,
    page_size: 10,
  };

  @Expose()
  list: OrderListModel[] = [];
}

export class PageInfo {
  total!: number;
  page_index!: number;
  has_more!: boolean;
  page_size!: number;
}

export class OrderListModel {
  /**
   * 订单表的ID，请使用这个ID置入cursor
   */
  @Expose()
  id!: number;
  /**
   * shopee订单ID
   */
  @Expose()
  entity_id!: number;
  /**
   * 订单序列码
   */
  @Expose()
  order_sn!: string;
  @Expose()
  order_image!: string;
  /**
   * 订单链接
   */
  @Expose()
  order_url!: string;
  @Expose()
  service_charge_amount!: string;
  @Expose()
  mortgage_amount!: string;
  /** 实际放款金额，扣除服务费 * */
  @Expose()
  actual_disburse_amount!: number;
  /**
   * 实际放款时间
   */
  @Expose()
  actual_disburse_time!: number;

  @Expose()
  plan_id!: number;

  @Expose()
  plan_status!: number; // 还款状态（1 未还款，2部分还款，3已还清）
  /**
   *
   */
  @Expose()
  is_overdue!: number;
  /**
   * 结清时间（未结清的单为0）
   */
  @Expose()
  clear_time!: number;
  /**
   * 应还款时间即FDD时间
   */
  @Expose()
  repay_time!: number;
  /**
   * 订单的一阶段放款金额，UI 的 anticipate_amount，没有扣掉服务费的
   */
  @Expose()
  principal_amount!: string;
  /**
   * 未还本金金额
   */
  @Expose()
  principal_outstanding_amount!: string;
  /**
   * 利息
   */
  @Expose()
  interest_amount!: string;
  /**
   * 未还利息
   */
  @Expose()
  interest_outstanding_amount!: string;
  /**
   * 总未还金额
   */
  @Expose()
  outstanding_amount!: string;
  /**
   * 总应还金额
   */
  @Expose()
  repayment_amount!: string;
  /**
   * 总已还金额
   */
  @Expose()
  total_repaid_amount!: string;
  /**
   * 最近交易时间
   */
  @Expose()
  last_transaction_time!: number;
  @Expose()
  remaining_disburse_amount!: string;
  @Expose()
  remaining_disburse_time!: number;
  @Expose()
  order_income!: string;
  @Expose()
  total_amount!: string;
  @Expose()
  service_rate!: string;
  @Expose()
  interest_rate!: string;
  @Expose()
  is_adjusted!: boolean; // 是否调整放款比例
  @Expose()
  is_received_settle_notify!: boolean; // 是否收到上游结算通知
  @Expose()
  disburse_percentage!: number; // 放款百分比
  @Expose()
  charge_mode!: CHARGE_MODE; // 收费模式
}
