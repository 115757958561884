namespace Outstanding {
  export enum API {
    'get_user_outstanding_repayments' = '/fast-escrow/order/get_user_outstanding_repayments',
  }

  export enum RefactorAPI {
    'get_user_outstanding_repayments' = '/fast-escrow/transaction/query_repayment_list',
  }
}

export enum ORDER_STATUS {
  UNPAID = 1,
  PARTIAL_REPAID = 2,
  REPAID = 3,
}

export default Outstanding;
