import { history } from 'umi';
import { makeObservable, action, observable, runInAction } from 'mobx';
import { formatMessage } from '@/utils/locale';
import { BaseService } from '../base';
import { Toast } from 'antd-mobile';
import bridge from '@/utils/v2/bridge';
// import * as Sentry from '@sentry/browser';
import { getAppState } from '@/utils/common';
import Repayment from './api.interface';
import { RepaymentModel } from './repayment.model';
import { IRepayOrder, REPAYABLE_TYPE, REPAY_STATUS, REPAY_CHANNEL_ID } from './repayment.interface';
import insight from '@/utils/v2/insight';

export class RepaymentService extends BaseService {
  @observable repayment: RepaymentModel = {
    total_amount: 0,
    overflow_amount: 0,
    overdueInfo: {} as IRepayOrder,
    repayableInfo: {} as IRepayOrder,
    selectedChannelName: '',
    selectedChannelId: 0,
    payOption: '',
    loading: false,
  } as RepaymentModel;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async initRepayInfo() {
    // 初始化
    this.repayment = {
      ...this.repayment,
      total_amount: 0,
      overflow_amount: 0,
      overdueInfo: {} as IRepayOrder,
      repayableInfo: {} as IRepayOrder,
      selectedChannelName: '',
      selectedChannelId: 0,
      payOption: '',
      loading: false,
    };
  }

  @action
  async getRepayInfo({ isForHome }: { isForHome: boolean }) {
    const { code, result } = await this.post(Repayment.API.get_repay_info);
    if (code !== 0 && !isForHome) {
      Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
      history.push('/home');
      return false;
    }
    const { total_amount = 0, overflow_amount = 0, repayable_order_info_list = [] } = result;
    const overdueInfo = repayable_order_info_list?.find(
      (item: any) => item?.repayable_type === REPAYABLE_TYPE.OVERDUE,
    );
    const repayableInfo = repayable_order_info_list?.find(
      (item: any) => item?.repayable_type === REPAYABLE_TYPE.DOB,
    );

    const info = {
      total_amount,
      overflow_amount,
      overdueInfo,
      repayableInfo,
    } as RepaymentModel;

    if (!isForHome) {
      const { result: result2 } = await this.post(Repayment.API.get_repay_channel, {
        body: {
          amount_to_pay: total_amount,
          overflow_amount,
        },
      });

      let selectedParentIndex = -1;
      let selectedChildIndex = -1;
      const channelArr = JSON.parse(result2?.channels ?? '[]');
      const optJson = JSON.parse(result2?.last_repay_channel_json ?? '[]');
      selectedParentIndex = channelArr.findIndex(
        (it: any) => it.channel_id === result2?.last_repay_channel_id,
      );
      const banks =
        channelArr[selectedParentIndex] &&
        (channelArr[selectedParentIndex].banks || channelArr[selectedParentIndex].bank_accounts);
      if (banks && banks.length && optJson.channel_item_option_info) {
        // 筛选出上次银行渠道还款成功下的子渠道，如果子渠道不可用，则不选中
        selectedChildIndex = banks.findIndex(
          (it: any) =>
            it.enabled && it.option_info === optJson.channel_item_option_info.option_info,
        );
      }
      const isBankChannel = result2?.last_repay_channel_id === 8003600;
      /**
       * 若上次还款成功渠道为
       * 1. shopeepay且余额足够本次还款则后端返回default_repay_channel_id与last_repay_channel_id相同；
       * 2. 银行还款渠道 则默认直接返回上次成功还款渠道。
       * 若无上次还款成功渠道则返回0
       */
      // 新增判断逻辑：如果上次还款成功渠道为指定的银行还款渠道，但是当前get_repay_channel后端接口返回的该银行渠道不可用，则此时需要取消选中上次还款成功的银行渠道相关信息
      if (isBankChannel && selectedParentIndex > -1 && selectedChildIndex <= 0) {
        info.payOption = '';
        info.selectedChannelName = '';
        info.selectedChannelId = 0;
      } else if (
        result2?.last_repay_channel_id &&
        result2?.last_repay_channel_id === result2?.default_repay_channel_id
      ) {
        info.payOption = result2?.last_repay_channel_json ?? '';
        info.selectedChannelName = result2?.last_repay_channel_name ?? '';
        info.selectedChannelId = result2?.last_repay_channel_id ?? 0;
      } else {
        info.payOption = '';
        info.selectedChannelName = '';
        info.selectedChannelId = 0;
      }
    }

    runInAction(() => {
      this.repayment = {
        ...this.repayment,
        ...info,
      };
    });
    return true;
  }

  @action
  async getRepayOption({
    amount_to_pay,
    overflow_amount,
    payOption,
  }: {
    amount_to_pay: string;
    overflow_amount: string;
    payOption: string;
  }) {
    // Toast.loading(formatMessage({ id: 'loading' }), 0);
    Toast.show({ icon: 'loading', content: formatMessage({ id: 'loading' }), duration: 0 });
    const { code, result } = await this.post(Repayment.API.get_repay_channel, {
      body: {
        amount_to_pay,
        overflow_amount,
      },
    });

    if (code === 0) {
      const saveKey = 'fastescrow_payment_channels';
      const TRACKING_META_KEY = 'fastescrow_repayment_track_key';
      const channelArr = JSON.parse(result?.channels ?? '[]');

      let selectedParentIndex = -1;
      let selectedChildIndex = -1;

      if (payOption) {
        try {
          const optJson = JSON.parse(payOption);
          selectedParentIndex = channelArr.findIndex(
            (it: any) => it.channel_id === optJson.channel_id,
          );

          // 注意这里有坑：
          // 我们根据父级（一级）渠道下的子级（二级）选项数组，查找对应的子级选项id, 比较坑的是不同渠道下的子选项数组命名不同, ibank的是banks, airpay的是bank_accounts
          // 如果哪天新加渠道,注意添加子选项数组！
          // 目前spm的payment method页面允许只勾选父级不勾子选项就confirm的情况
          // 文档: https://confluence.shopee.io/display/SPAT/RN+Payment+Selection#RNPaymentSelection-Paymentchanneldatastructure
          const banks =
            channelArr[selectedParentIndex] &&
            (channelArr[selectedParentIndex].banks ||
              channelArr[selectedParentIndex].bank_accounts);
          if (banks && banks.length && optJson.channel_item_option_info) {
            selectedChildIndex = banks.findIndex(
              (it: any) => it.option_info === optJson.channel_item_option_info.option_info,
            );
          }
        } catch (e) {
          // Sentry.captureMessage('PARSE_PAY_CHANNEL_ERROR', {
          //   extra: {
          //     user: getAppState('userInfo.userInfo') ?? {},
          //     body: {
          //       message: e,
          //       payOption,
          //     },
          //   },
          // });
        }
      }

      insight.thirdRNSdkReport({
        sdkName: 'payment_selection',
        method: 'call',
      });

      const selection: any = await bridge.getPaymentMethod({
        key: saveKey,
        data: result.channels,
        metaKey: TRACKING_META_KEY,
        selectedParentIndex,
        selectedChildIndex,
      });

      insight.thirdRNSdkReport({
        sdkName: 'payment_selection',
        method: 'response',
        status: selection ? 'success' : 'cancel',
      });

      if (selection && selection !== payOption) {
        // 此次选择和上次选择不一样
        const channel = channelArr.find((item: any) => selection?.includes(`${item.channel_id}`));
        const selectedChannelName = channel ? channel.name : selection;
        const selectedChannelId = channel ? channel.id : selection;

        this.repayment = {
          ...this.repayment,
          payOption: selection,
          selectedChannelId,
          selectedChannelName,
        };
      }

      // Toast.hide();
      Toast.clear();
    } else {
      // Toast.hide();
      // Toast.fail(formatMessage({ id: 'system_busy' }), 2);
      Toast.clear();
      Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
    }
  }

  @action
  async triggerRepay({
    amount_to_pay,
    overflow_amount,
    list,
    select_channel,
    repay_channel_name,
  }: {
    amount_to_pay: string;
    overflow_amount: string;
    list: IRepayOrder[];
    select_channel: string;
    repay_channel_name: string;
  }) {
    const payOption = select_channel;
    this.repayment.loading = true;

    const { code, msg, result } = await this.post(Repayment.API.proactive_repay, {
      body: {
        amount_to_pay,
        overflow_amount,
        list,
        select_channel,
        repay_channel_name,
      },
    });

    if (code === 0) {
      const { proactive_repay_id, repay_url, repay_status } = result;
      if (
        [REPAY_STATUS.PAID, REPAY_STATUS.FAILED, REPAY_STATUS.DELAY_PAID].includes(repay_status)
      ) {
        // 如果直接到终态（溢缴款全量抵扣）直接跳转还款结果页
        this.repayment.loading = false;
        history.push({
          pathname: `/billing/repay-result/${proactive_repay_id ?? ''}`,
        });
      } else if (
        payOption.includes(REPAY_CHANNEL_ID.SHOPEE_PAY) ||
        payOption.includes(REPAY_CHANNEL_ID.SELLER_BALANCE)
      ) {
        this.repayment.loading = false;
        // shopeePay方式，还款完成后也跳还款结果页
        await new Promise((resolve) => {
          bridge.callWebview(repay_url);
          const reappearCb = () => {
            resolve(true);
            bridge.unBind('viewWillReappear', reappearCb);
            history.push({ pathname: '/home' });
          };
          bridge.bind('viewWillReappear', reappearCb);
        });
      } else if (repay_url) {
        // 其他有repay_url，跳后台返回的url
        // 这里可以直接置loading为false嘛？cashloan没有置为false
        window.location.href = repay_url;
      } else {
        // 否则直接跳还款结果页
        this.repayment.loading = false;
        history.push({
          pathname: `/billing/repay-result/${proactive_repay_id ?? ''}`,
        });
      }
    } else if (code === 10094007 || code === 10095004) {
      this.repayment.loading = false;
      // Toast.fail(msg, 2);
      Toast.show({ icon: 'fail', content: msg });
    } else {
      this.repayment.loading = false;
      // Toast.fail(formatMessage({ id: 'system_busy' }), 2);
      Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
    }
  }

  @action
  async getRepayResult(data: { repay_id: string }) {
    const result = await this.post(Repayment.API.get_repay_result, {
      body: data,
    });
    return result;
  }
}
