namespace AgreementID {
  export enum API {
    'get_loan_agreement_info' = '/fast-escrow/usercenter/apply/get_unify_contract_url',
    'preview_contract' = '/fast-escrow/usercenter/apply/preview_contract',
    'get_appendix_list' = '/fast-escrow/transaction/get_disbursement_details_list',
    'get_disbursement_details_info' = '/fast-escrow/transaction/get_disbursement_details_info',
    'get_disbursement_details_pdf' = '/fast-escrow/transaction/get_disbursement_details_pdf',
    'get_agreement_addendum_info' = '/fast-escrow/usercenter/apply/get_contract_url',
  }
}

export default AgreementID;

export interface IAppendixInfo {
  year: number;
  details_title: string;
}
