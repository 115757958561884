import { UserInfoService } from './userInfo';
import { GeneralService } from './general';
import { HomeService } from './home/id';
import { RepaymentService } from './repayment';
import { PercentageService } from './percentage/id';
import { UpcomingService } from './upcoming/id';
import { OutstandingService } from './outstanding';
import { HistoryService } from './history';
import { AgreementService } from './agreement/id';

export default {
  userInfo: new UserInfoService(),
  general: new GeneralService(),
  home: new HomeService(),
  repayment: new RepaymentService(),
  percentage: new PercentageService(),
  upcomingStore: new UpcomingService(),
  outstanding: new OutstandingService(),
  historyStore: new HistoryService(),
  agreement: new AgreementService(),
};
