import InsightTracker from '@finance/perfsdk';
import { MetricConfig } from '@finance/perfsdk/dist/index.interface';

export enum CustomReportAction {
  ApiReport = 'business_api_request',
  ThirdSdkCounter = 'third_sdk_counter',
  BridgeReport = 'business_bridge',
  LoginState = 'login_state',
  CustomLog = 'custom_log',
}

export const metricConfigs: MetricConfig[] = [
  {
    metricName: CustomReportAction.ApiReport,
    type: 'counter',
    labels: ['url', 'resp_code', 'message'],
    desc: 'reporting of statistical interface requests',
  },
  {
    metricName: CustomReportAction.ThirdSdkCounter,
    type: 'counter',
    labels: ['flow', 'status', 'sdkName', 'code', 'method'],
    desc: 'reporting of call third rn sdk',
  },
  {
    metricName: CustomReportAction.BridgeReport,
    type: 'counter',
    labels: ['name', 'status', 'msg'],
    desc: 'reporting of statistical bridge request',
  },
  {
    metricName: CustomReportAction.LoginState,
    type: 'counter',
    labels: ['type', 'status'],
    desc: 'reporting of login status',
  },
];

interface IApiParams {
  url: string;
  resp_code: string;
  message: string;
}

type IThirdRNSdkParams = {
  flow?: 'loan' | 'repayment' | 'unknown' | 'limit_adjust' | 'view_contract';
  status?: string;
  sdkName: 'payment_selection' | 'payment_selection_sdk';
  code?: string;
  method?: 'call' | 'response' | 'unknown';
};

type IBridgeReportParams = {
  status: string;
  name: string;
  msg?: string;
};

type ILoginStateParams = {
  type: 'auth_code' | 'nonce_code' | 'web_bridge_nonce_code';
  status: 'success' | 'error' | 'call';
};

export interface ICustomLogParams {
  flow?: 'loan' | 'repay' | string;
  name?: string;
  message: string | Record<string, any>;
}

class Insight {
  customReport(metricName: CustomReportAction, params: Record<string, any>) {
    try {
      InsightTracker.reportCustomInfo(`custom report ${metricName}`, { ...params }, [
        { metricName },
      ]);
    } catch (e) {
      // 不做处理
    }
  }

  /**
   * @description 打印的内容主要是用于在日志平台排查问题
   */
  log(params: ICustomLogParams) {
    try {
      InsightTracker.reportCustomInfo(`custom log`, { ...params }, [
        { metricName: CustomReportAction.CustomLog },
      ]);
    } catch (e) {
      // 不做处理
    }
  }

  /**
   * 业务接口上报
   * @param params IApiParams
   */
  apiReport(params: IApiParams) {
    this.customReport(CustomReportAction.ApiReport, params);
  }

  /**
   * 第三方 RN 组件上报
   * @param params
   */
  thirdRNSdkReport(params: IThirdRNSdkParams) {
    this.customReport(CustomReportAction.ThirdSdkCounter, {
      flow: 'unknown',
      status: 'unknown',
      code: 'unknown',
      method: 'unknown',
      ...params,
    });
  }

  /**
   * bridge 调用上报
   * @param params
   */
  bridgeReport(params: IBridgeReportParams) {
    this.customReport(CustomReportAction.BridgeReport, {
      msg: 'unknown',
      ...params,
    });
  }

  /**
   * 登录上报
   * @param params
   */
  loginReport(params: ILoginStateParams) {
    this.customReport(CustomReportAction.LoginState, params);
  }
}

export default new Insight();
