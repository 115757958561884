// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/www/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading/index.tsx';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts' */'@/layouts'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__id' */'@/pages/Home/id'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/limitAdjustDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LimitAdjustDetail' */'@/pages/LimitAdjustDetail'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting' */'@/pages/Setting'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/credit/agreement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CreditAgreement__id' */'@/pages/CreditAgreement/id'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/agreement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Agreement__id' */'@/pages/Agreement/id'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/upcoming",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Upcoming' */'@/pages/Upcoming'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/percentage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Percentage' */'@/pages/Percentage'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/history/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__List' */'@/pages/history/List'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/history/disbursement-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__DisbursementDetail' */'@/pages/history/DisbursementDetail'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/history/repayment-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__RepaymentDetail' */'@/pages/history/RepaymentDetail'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/outstanding",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outstanding__id' */'@/pages/Outstanding/id'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/billing/repayment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repayment' */'@/pages/Repayment'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/billing/repay-result/:repayId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RepayResult' */'@/pages/RepayResult'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/maintenance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Maintenance' */'@/pages/Maintenance'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exception",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception' */'@/pages/Exception'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
