import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { AgreementResponseModel } from './model';
import AgreementID, { IAppendixInfo } from './interface.id';
import { IContractInfo } from './interface';
import { locale } from '@/utils/v2/locale';

export class AgreementService extends BaseService {
  @observable agreementState: AgreementResponseModel = new AgreementResponseModel();
  // id年度放款明细附录
  @observable appendixList: IAppendixInfo[] = [];
  // 激活合同附录&主动还款附录
  @observable agreementAddendumList: IContractInfo[] = [];

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async getAgreementInfo(params: any) {
    const url = AgreementID.API.get_loan_agreement_info;
    const { code, result } = await this.post(url, {
      body: { ...params },
    });
    if (code === 0) {
      this.agreementState = {
        ...this.agreementState,
        ...result,
      };
      return result || this.agreementState;
    }
  }

  @action
  async getAppendixList(params: any) {
    const { code, result } = await this.post(AgreementID.API.get_appendix_list, {
      body: { ...params },
    });
    if (code === 0) {
      const { title_list = [] } = result;
      this.appendixList = [...this.appendixList, ...title_list].sort((a, b) => a.year - b.year);
    }
  }

  @action
  clearAppendixList() {
    this.appendixList = [];
  }

  @action
  async getAppendixDetailInfo(params: any) {
    const result = await this.post(AgreementID.API.get_disbursement_details_info, {
      body: { ...params },
    });
    return result;
  }

  @action
  async getAppendixDetailPdf(params: any) {
    const result = await this.post(AgreementID.API.get_disbursement_details_pdf, {
      body: { ...params },
    });
    return result;
  }

  @action
  async getAddendumInfo(params: any) {
    const { code, result } = await this.post(AgreementID.API.get_agreement_addendum_info, {
      body: { ...params },
    });
    if (code === 0) {
      const { contract_info_list = [] } = result;
      const newAddendumList = contract_info_list.map((item: any) => {
        const commonInfo = {
          contract_id: item.contract_id,
          accept_time: item.accept_time,
        };
        if (locale === 'en') {
          return {
            ...commonInfo,
            contract_url: item.contract_url_en,
            contract_picture_url: item.contract_picture_url_en,
          };
        }
        return {
          ...commonInfo,
          contract_url: item.contract_url_local,
          contract_picture_url: item.contract_picture_url_local,
        };
      });

      this.agreementAddendumList = [...newAddendumList];
    }
  }

  @action
  clearAddendumContractList() {
    this.agreementAddendumList = [];
  }
}
