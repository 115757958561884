import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { OutstandingListModel } from './model';
import Outstanding from './interface';

export class CommonService extends BaseService {
  @observable loading: boolean = false;
  @observable hasMore: boolean = true;
  @observable dataList: OutstandingListModel[] = [];

  protected page_index = 0;
  protected page_size = 10;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  initInfo() {
    this.loading = false;
    this.hasMore = true;
    this.page_index = 0;
    this.page_size = 10;
    this.dataList = [];
  }

  @action
  async fetchList() {
    // 如果已经标记当前状态为hasmore：false
    if (!this.hasMore || this.loading) return;
    this.loading = true;

    const { code, result } = await this.post(
      Outstanding.RefactorAPI.get_user_outstanding_repayments,
      {
        body: {
          status: 4, // 0. All 1. Pending 2. Overdue 3. Completed 4. Outstanding(for PC)
          pagination: {
            page_index: this.page_index + 1,
            page_size: this.page_size,
            cursor:
              this.dataList.length > 0
                ? this.dataList?.[this.dataList.length - 1]?.plan_id ?? '0'
                : '0',
          },
        },
      },
    );
    if (code === 0) {
      const { page_info, list = [] } = result;
      this.dataList = list ?? [];
      this.hasMore = page_info.has_more;
      this.page_index = page_info.page_index;
      this.loading = false;
      return result;
    }
    this.loading = false;
    this.hasMore = false;
  }
}
