import { locale } from '@/utils/locale';
import bridgeTrack from 'bridgetrack';
import { setLocale } from 'umi';
import appInfo from '@/stores/appInfo';
import bridge from '@/utils/bridge';
import vl from '@/utils/vl';
import { initLocale } from '@/utils/v2/locale';
// https://confluence.shopee.io/pages/viewpage.action?pageId=1449318248
// risk信息采集sdk
import { init as initRiskInfo } from '@shopee/risk-info-sdk';
import TrackSdk from '@credit/track-sdk';
import { initSdk, callHandler, trackBIEvent, configurePage } from '@shopee/web-bridge-sdk';
import '@credit/tips/lib/index.css';

// 设置html fontSize等，H5 rem
vl(50, 375);

// 设置app info
appInfo.setAppInfo();
appInfo.setExtraInfo();

initLocale();

// init js bridge
if (!IS_NEW_BRIDGE) {
  bridge.call('configurePage', {
    config: {
      disableReload: 1,
      disableBounce: 1,
    },
  });
  bridgeTrack.config({ bridge, debug: REACT_APP_ENV !== 'live' }).autoExpo();
} else {
  initSdk();
  configurePage({
    config: {
      disableReload: 1,
      disableBounce: 1,
    },
  });

  bridgeTrack
    .config({
      bridge: { call: callHandler },
      onTrackEvent: (params: any) => {
        trackBIEvent({
          events: JSON.stringify(params),
        });
      },
      debug: REACT_APP_ENV !== 'live',
    })
    .autoExpo();
}

// init risk sdk
if (!IS_NEW_BRIDGE) {
  initRiskInfo({
    bridge: window.bridgeCallHandler,
    biz: 'fastescrow',
    env: REACT_APP_ENV,
    region: BIZ_COUNTRY,
  });
} else {
  initRiskInfo({
    bridge: callHandler as any,
    biz: 'fastescrow',
    env: REACT_APP_ENV,
    region: BIZ_COUNTRY,
  });
}

if (TRACK_SDK_TOKEN) {
  const productName = 'fastescrow';

  // 如果是dev环境则转为test环境下的配置
  const envName = ['dev', 'mock'].includes(REACT_APP_ENV) ? 'test' : REACT_APP_ENV;

  const cdnUrl = `https://cdn.scredit.sg/${BIZ_COUNTRY}/${productName}-h5/${envName}/config_${TRACK_SDK_TOKEN?.replace?.(
    /\//g,
    '_',
  )}.json`;

  new TrackSdk(bridgeTrack, TRACK_SDK_TOKEN, cdnUrl, {
    debug: REACT_APP_ENV !== 'live',
  });
}

// 设置多语言信息
setLocale(locale);
